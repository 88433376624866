import React from 'react';

import GooglePlayLogo from 'assets/icons/googlePlay_badge.svg';

import styled from 'styled-components';

import { StoresLinks } from 'utils/constants/externalLinks';

const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AppleStoreBadge = styled(GooglePlayLogo)`
  width: 10.25rem;
  height: 3rem;
`;

const GooglePlayLinkBtn = ({ ...props }) => (
  <StyledLink href={StoresLinks.GooglePlay} target="_blank" {...props}>
    <AppleStoreBadge />
  </StyledLink>
);

export default GooglePlayLinkBtn;
