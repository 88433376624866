import React, { FC, useState } from 'react';
import styled from 'styled-components';
import DownloadEbook from 'assets/icons/download.svg';
import { DynamicImage, PrimaryButton, Text } from 'components';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import BookImage from './BookImage';
import { CircularProgress } from '@material-ui/core';

interface ThankYouSectionProps {
  title: string;
  subtitle: string;
  img: string;
  alt: string;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4.5rem 0;
  width: 100%;
  gap: 2rem;
  height: 452px;
  padding: 0 1rem;
  @media ${tablet} {
    margin: 0;
    padding: 0.5rem 1rem 2.25rem 0;
    flex-direction: column;
    height: auto;
    gap: 0rem;
  }
`;

const TYTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  gap: 3rem;
  @media ${tablet} {
    align-items: center;
    gap: 2rem;
    width: 100%;
  }
`;
const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  @media ${tablet} {
    gap: 0.5rem;
    align-items: center;
  }
`;

const TYTitle = styled(Text)`
  width: 100%;
  max-width: 487px;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 48px;
  text-align: left;
  strong {
    font-weight: 800;
    color: ${({ theme }) => theme.colors.primary};
  }
  @media ${tablet} {
    text-align: center;
    font-size: 1.5rem;
    max-width: 303px;
    line-height: 31.2px;
  }
`;

const TYText = styled(Text)`
  width: 100%;
  max-width: 418px;
  text-align: left;
  strong {
    font-weight: 600;
  }
  @media ${tablet} {
    text-align: center;
    max-width: 303px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  gap: 5px;
  @media ${tablet} {
  }
`;

const DecoratePlusSign = styled(Text).attrs({
  color: 'dark100',
})`
  color: #f36523;
  font-size: 40px;
  position: absolute;
  left: 50%;
`;

const BookContainer = styled.div`
  position: relative;
  max-width: 18.7rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const BookContainerMockup = styled(BookContainer)`
  @media ${tablet} {
    margin-top: 2.31rem;
    left: -20px;
  }
`;

const EBookCoverPlus = styled(DynamicImage)`
  width: 11.1rem;
  height: 9.459rem;
  z-index: 2;
`;
const EBookCover = styled(DynamicImage)`
  width: 12.6rem;
  height: 13.5rem;
  z-index: 2;
`;

const EBabyName = styled.p<{ bookColor: string }>`
  color: ${({ bookColor }) => (bookColor === 'beige' ? '#C7546C' : '#fffde9')};
  font-family: 'Brandon Grotesque', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.33969rem;
  text-transform: uppercase;
  position: absolute;
  top: 2.7rem;
  right: 50%;
  transform: translateX(50%);
  z-index: 3;
  white-space: nowrap;
  @media ${mobile} {
    font-weight: 400;
  }
`;

const PBabyName = styled(EBabyName)`
  color: ${({ bookColor }) => (bookColor === 'beige' ? '#c4697a' : '#fffde9')};
  top: 2.5rem;
  right: 42%;
  font-size: 0.67rem;
  @media ${mobile} {
    font-weight: 400;
  }
`;

const EBabyNamePlus = styled(EBabyName)`
  top: 1.8rem;
  font-size: 0.67rem;
  @media ${mobile} {
    font-weight: 400;
  }
`;

const EBottomNames = styled.p<{ bookColor: string; isLongText: boolean }>`
  color: ${({ bookColor }) => (bookColor === 'beige' ? '#C7546C' : '#fffde9')};
  font-size: 0.38rem;
  font-weight: 700;
  line-height: 0.40706rem;
  text-transform: uppercase;
  position: absolute;
  bottom: ${({ isLongText }) => (isLongText ? '1.1rem' : '1.6rem')};
  right: 50%;
  transform: translateX(50%);
  white-space: nowrap;
  font-family: 'Brandon Grotesque', sans-serif;
  z-index: 3;
  text-align: center;
  @media ${mobile} {
    font-weight: 400;
  }
`;

const PBottomNames = styled(EBottomNames)<{ isLongText: boolean }>`
  bottom: ${({ isLongText }) => (isLongText ? '1.5rem' : '1.85rem')};
  right: 42%;
  color: ${({ bookColor }) => (bookColor === 'beige' ? '#c4697a' : '#fffde9')};
  font-size: 0.26rem;
  ${({ isLongText }) => isLongText && 'line-height: 0.35rem;'}
  @media ${mobile} {
    font-weight: 400;
  }
`;

const EImageStyled = styled(DynamicImage)`
  z-index: 1;
  position: absolute;
  width: 15.8167rem;
  height: 13.5rem;
`;

const EbookContainerOnly = styled.div`
  display: flex;
  padding: 0.1295rem 0.51794rem;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -10px;
  border-radius: 0.27181rem;
  background: #f36523;
  z-index: 3;
  right: 50%;
  transform: translateX(50%);
  color: #faf1eb;
  text-align: center;
  font-size: 1.03588rem;
  font-weight: 800;
`;

const EbookContainer = styled(EbookContainerOnly)`
  padding: 0.08556rem 0.34225rem;
  bottom: -5px;
  border-radius: 0.17963rem;
  font-size: 0.6845rem;
`;

const EBottomNamesPlus = styled(EBottomNames)<{ isLongText: boolean }>`
  bottom: ${({ isLongText }) => (isLongText ? '0.65rem' : '1rem')};
  font-size: 0.26rem;
  ${({ isLongText }) => isLongText && 'line-height: 0.35rem;'};
  @media ${mobile} {
    font-weight: 400;
  }
`;

const BookCoverOnly = styled(DynamicImage)`
  width: 16.4rem;
  height: 18.8rem;
`;
const BookCover = styled(DynamicImage)`
  width: 11.6rem;
  height: 11rem;
  top: 11px;
`;

const BabyName = styled.p<{ bookColor: string }>`
  color: ${({ bookColor }) => (bookColor === 'beige' ? '#C7546C' : '#fffde9')};
  font-family: 'Brandon Grotesque', sans-serif;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.418rem;
  text-transform: uppercase;
  position: absolute;
  top: 3.5rem;
  right: 41%;
  transform: translateX(50%);
  @media ${mobile} {
    font-weight: 400;
  }
`;

const BottomNames = styled.p<{ bookColor: string; isLongText: boolean }>`
  color: ${({ bookColor }) => (bookColor === 'beige' ? '#C7546C' : '#fffde9')};
  font-size: 0.44rem;
  font-weight: 700;
  line-height: 0.5rem;
  text-transform: uppercase;
  position: absolute;
  bottom: ${({ isLongText }) => (isLongText ? '4rem' : '4.45rem')};
  right: 41%;
  transform: translateX(50%);
  white-space: nowrap;
  font-family: 'Brandon Grotesque', sans-serif;
  text-align: center;
  @media ${mobile} {
    font-weight: 400;
  }
`;

const DownloadButton = styled.a`
  display: flex;
  cursor: pointer;
  width: 17.5rem;
  height: 3rem;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 6.25rem;
  background: #ef71a0;
  color: #fff !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  &:hover {
    background: #cb6088;
    opacity: 1 !important;
  }
`;

const DownloadButtonLoad = styled(DownloadButton)`
  background: #f9a0c1;
  cursor: default;
  &:hover {
    background: #f9a0c1;
    opacity: 1 !important;
  }
`;

const CircularProgressStyled = styled(CircularProgress)``;

const ThankYouSection: FC<ThankYouSectionProps> = ({
  img,
  subtitle,
  title,
  alt,
}) => {
  const { isTablet } = useQuery();
  const [showName, setShowName] = useState<boolean>(false);
  const { user } = useSelector((state: AppState) => state);
  const selectedPlans = user?.selected_plans;

  const buildValueKey = (item: string) =>
    item.toLowerCase().replace(/\s+/g, '_');

  const ebookCheck = selectedPlans
    ?.filter(item => !item.is_subscription)
    .some(item => item.type === 'digital');
  const paperBook = selectedPlans
    ?.filter(item => !item.is_subscription)
    .some(item => item.type === 'physical');

  const resolvePageTextContent = () => {
    if (ebookCheck && !paperBook) {
      return {
        title: 'Your e-book is being prepared 🎉!',
        subtitlePlaceHolder: 'It will be delivered to your email.',
        subtitle: 'It will be delivered to your email.',
        downloadButton: 'Download my e-book',
      };
    }
    if (!ebookCheck && paperBook) {
      return {
        title: 'Your physical book is being prepared 🎉! ',
        subtitle: 'It should arrive in 8-16 business days',
      };
    }
    return {
      title: 'Your physical & e-book are being prepared 🎉!',
      subtitle:
        'Your physical book should arrive in 8-16 business days and e-book will be sent to your email',
      downloadButton: 'Download my e-book',
    };
  };
  const quizAnswers = useSelector((state: AppState) => state.user.quiz_answers);

  const renderNames = () => {
    const { mom_name, dad_name, baby_name, parenting_situation } =
      quizAnswers || {};

    let namesString = '';

    switch (parenting_situation) {
      case 'mom':
        namesString = `${mom_name} & ${baby_name}`;
        break;
      case 'dad':
        namesString = `${dad_name} & ${baby_name}`;
        break;
      default:
        namesString = `${mom_name}, ${dad_name} & ${baby_name}`;
        break;
    }

    const isLongText = namesString.length > 35;

    if (
      (isLongText && parenting_situation === 'parents') ||
      (isLongText && parenting_situation.includes('two'))
    ) {
      namesString = namesString.replace(' & ', ' &<br/> ');
    }

    return { namesString, isLongText };
  };

  const { namesString, isLongText } = renderNames();

  const handleOnLoad = () => {
    setShowName(true);
  };

  return (
    <Container>
      {ebookCheck && !paperBook && (
        <BookContainer>
          <EBookCover
            alt="Baby book cover"
            src={`booksCovers/${quizAnswers.color}_${quizAnswers.icon}.png`}
            handleOnLoad={handleOnLoad}
          />
          <EImageStyled src="upsell/background.png" alt="" />
          {showName && (
            <>
              <EBabyName bookColor={quizAnswers?.color}>
                {quizAnswers?.baby_name}
              </EBabyName>
              <EBottomNames
                bookColor={quizAnswers.color}
                dangerouslySetInnerHTML={{ __html: namesString }}
                isLongText={isLongText}
              />
              <EbookContainer>ebook</EbookContainer>
            </>
          )}
        </BookContainer>
      )}
      {!ebookCheck && paperBook && (
        <BookContainerMockup>
          <BookCoverOnly
            alt="Baby book cover"
            src={`books-mockups/${quizAnswers.color}_${quizAnswers.icon}.png`}
            handleOnLoad={handleOnLoad}
          />
          {showName && (
            <>
              <BabyName bookColor={quizAnswers.color}>
                {quizAnswers?.baby_name}
              </BabyName>
              <BottomNames
                bookColor={quizAnswers.color}
                dangerouslySetInnerHTML={{ __html: namesString }}
                isLongText={isLongText}
              />
            </>
          )}
        </BookContainerMockup>
      )}
      {ebookCheck && paperBook && (
        <ImageContainer>
          <BookContainer>
            <BookCover
              alt="Baby book cover"
              src={`books-mockups/${quizAnswers.color}_${quizAnswers.icon}.png`}
              handleOnLoad={handleOnLoad}
            />
            {showName && (
              <>
                <PBabyName bookColor={quizAnswers.color}>
                  {quizAnswers?.baby_name}
                </PBabyName>
                <PBottomNames
                  bookColor={quizAnswers.color}
                  dangerouslySetInnerHTML={{ __html: namesString }}
                  isLongText={isLongText}
                />
              </>
            )}
          </BookContainer>
          <DecoratePlusSign>+</DecoratePlusSign>
          <BookContainer>
            <EBookCoverPlus
              alt="Baby book cover"
              src={`booksCovers/${quizAnswers.color}_${quizAnswers.icon}.png`}
              handleOnLoad={handleOnLoad}
            />
            {showName && (
              <>
                <EBabyNamePlus bookColor={quizAnswers.color}>
                  {quizAnswers?.baby_name}
                </EBabyNamePlus>
                <EBottomNamesPlus
                  bookColor={quizAnswers.color}
                  dangerouslySetInnerHTML={{ __html: namesString }}
                  isLongText={isLongText}
                />
                <EbookContainer>ebook</EbookContainer>
              </>
            )}
          </BookContainer>
        </ImageContainer>
      )}

      <TYTextContainer>
        <TextContainer>
          <TYTitle
            color="dark100"
            dangerouslySetInnerHTML={{ __html: resolvePageTextContent().title }}
          />
          <TYText
            type={isTablet ? 'body' : 'body'}
            color="dark100"
            dangerouslySetInnerHTML={{
              __html: resolvePageTextContent().subtitle,
            }}
          />
          {user?.selected_plans_options?.ebookCheck &&
            (quizAnswers?.ebookUrl ? (
              <DownloadButton target="_blank" href={quizAnswers?.ebookUrl}>
                <DownloadEbook />
                {resolvePageTextContent()?.downloadButton}
              </DownloadButton>
            ) : (
              <DownloadButtonLoad>
                <CircularProgressStyled style={{ color: '#fff' }} size={20} />
              </DownloadButtonLoad>
            ))}
        </TextContainer>
      </TYTextContainer>
    </Container>
  );
};

export default ThankYouSection;
