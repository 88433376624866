export enum SocialLinks {
  Instagram = 'https://www.instagram.com/thenovahealth?igsh=bDhuZTN4aDN6Z2E5',
  Facebook = 'https://www.facebook.com/profile.php?id=61554198396885',
  Twitter = '#',
  Reddit = '#',
}
export enum StoresLinks {
  AppleStore = 'https://apps.apple.com/app/welcome-baby-baby-tracker/id6479739968',
  GooglePlay = 'https://play.google.com/store/apps/details?id=com.welcomebaby.app',
}
